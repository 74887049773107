@import 'Styles/Base/includes';

/**
*
* FormModule
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.FormModule {
    $root: &;

    position: relative;
    padding-top: 32px;
    padding-bottom: 48px;
    overflow: hidden;

    @include media(sm) {
        padding-top: 56px;
        padding-bottom: 72px;
    }

    @include media(lg) {
        padding-top: 88px;
        padding-bottom: 104px;
    }

    &__Shape {
        position: absolute;
        top: 0;
        height: 2000px; // Set fixed size so shape always stays the same size and position
        left: 0;
        right: 0;
        overflow: hidden;
        z-index: 1;

        @include media(sm) {
            left: 0;
        }

        &--Blue {
            top: -1700px;
            right: -250px;
            display: none;
            transform: rotate(180deg);

            @include media(md) {
                display: block;
            }
        }

        &--Pink {
            top: calc(100% - 180px);
            left: -290px;

            @include media(md) {
                top: calc(100% - 260px);
                left: -150px;
            }
        }
    }

    &__Container {
        @include u-wrap(content);

        position: relative;
        text-align: center;
        z-index: 2;
    }

    &__Title {
        margin-bottom: 32px;
        font-size: 2.4rem;

        @include media(sm) {
            font-size: 3.2rem;
        }
    }

    &__Submitted {
        margin-bottom: 32px;

        h2 {
            font-size: 2.4rem;

            @include media(sm) {
                font-size: 3.2rem;
            }
        }

        p {
            margin-top: 12px;
            font-size: 1.6rem;

            @include media(sm) {
                font-size: 1.8rem;
            }
        }
    }

    &__ButtonList {
        margin-top: -5px;
    }

    &__Button {
        width: 100%;
        max-width: 376px !important;
        margin-bottom: 16px;
    }

    &__Form {
        padding: 12px 16px 30px;
        margin-bottom: 32px;
        text-align: left;
        background: white;

        @include media(sm) {
            padding: 20px 32px 40px;
        }
    }

    &__Contact {
        margin-top: 12px;
        font-size: 1.4rem;

        &::before {
            content: '';
            display: inline-block;
            width: 18px;
            height: 18px;
            margin: -4px 9px -6px 0;
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
