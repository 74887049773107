@import 'Styles/Dansskolanentre/includes';

/**
*
* Form
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Form.Base.module';

.Form {
    $root: &;

    margin-top: 60px;

    &__Error {
        &--Large {
            border-radius: 4px;

            &::after {
                display: none;
            }
        }
    }

    &__ThankYou {
        border-top: none;
        border-right: none;
        border-bottom: none;

        &::before {
            width: 3px;
            background: $primary-color;
        }

        h2, h3, h4 {
            font-size: 2.6rem;
        }
    }

    &__Form {
        :global .form {
            @extend %Form;
        }
    }
}
