/*
    Three main buttons
    - Border or solid (default)
        - Comes in black or white borders or solid in different colors
*/
@mixin u-button($list: (), $default: true) {
    @if contains($list, link) {
        @if $default {
            display: inline-block;
            padding: 0;
            color: $link-color;
            font-family: $font-base;
            font-weight: $medium;
            border: none;
            border-radius: 0;
            outline: none;
            background: transparent;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            cursor: pointer;
            transition: all .2s ease-in;

            &:hover {
                color: $link-hover-color;
                text-decoration: underline;

                @media(hover: none) {
                    color: $link-color;
                }
            }
        }
    } @else {
        @if $default {
            display: inline-block;
            position: relative;
            max-width: 296px;
            min-width: 116px;
            height: 50px;
            padding: 0 24px;
            font-size: 1.6rem;
            font-weight: $medium;
            font-family: $font-base;
            line-height: 50px;
            text-decoration: none;
            border: none;
            border-radius: 0;
            background: transparent;
            box-shadow: none;
            cursor: pointer;
            transition: color .2s ease-in-out, border-color .2s ease-in-out;

            @include media(xxs) {
                max-width: none;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: black;
                opacity: 0;
                transition: opacity .2s cubic-bezier(0.4, 0, 1, 1);
            }

            &:not([disabled]):hover {
                &::before {
                    opacity: 1;

                    @media(hover: none) {
                        opacity: 0;
                    }
                }
            }

            span:not(:global(.sr-only)) {
                position: relative;
            }
        }

        @if contains($list, solid) {
            color: white;
            border: none;
            border-radius: 25px;
            background-image: $gradient-color;

            &::before {
                border-radius: 25px;
                background: $primary-color;
            }

            &[disabled] {
                background: $grey-60;

                &::before {
                    opacity: 0 !important;
                }
            }

            @if contains($list, disabled) {
                background: $grey-60;

                &::before {
                    opacity: 0 !important;
                }
            }
        }

        @if contains($list, white) {
            color: $text-color;
            border: none;
            border-radius: 25px;
            background: white;

            &::before {
                border-radius: 25px;
                background: $text-color;
            }

            &:not([disabled]):hover {
                color: white;
            }

            &[disabled] {
                color: $grey-60;

                &::before {
                    opacity: 0 !important;
                }
            }

            @if contains($list, disabled) {
                color: $grey-60;

                &::before {
                    opacity: 0 !important;
                }

                &:hover {
                    color: $grey-60 !important;
                }
            }
        }

        @if contains($list, border) {
            color: $text-color;
            line-height: 46px;
            border: 2px solid $text-color;
            border-radius: 25px;
            background: white;

            &::before {
                border-radius: 23px;
                background: $text-color;
            }

            &:not([disabled]):hover {
                color: white;
            }

            &[disabled] {
                color: $grey-60;
                border-color: $grey-60;

                &::before {
                    opacity: 0 !important;
                }
            }

            @if contains($list, disabled) {
                color: $grey-60;
                border-color: $grey-60;

                &::before {
                    opacity: 0 !important;
                }

                &:hover {
                    color: $grey-60 !important;
                }
            }
        }

        @if contains($list, block) {
            display: block;
            width: 100%;
            max-width: none;
        }

        @if contains($list, small) {
            min-width: 0;
            height: 32px;
            padding: 0 20px;
            font-size: 1.2rem;
            line-height: 32px;
        }
    }
}
