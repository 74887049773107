@import 'Styles/Medborgarskolan/includes';

/**
*
* FormModule
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'FormModule.Base.module';

.FormModule {
    $root: &;

    background: $purple-20;

    &__Shape {
        display: none;
        &--Blue {
            svg {
                fill: $blue;
            }
        }

        &--Pink {
            svg {
                fill: $purple;
            }
        }
    }

    &__Title {
        color: $blue;
        font-weight: $bold;
    }

    &__Submitted {
        color: $blue;

        p {
            color: $grey-90;
        }
    }

    &__Button {
        @include u-button(primary);
    }

    &__Form {
        border-radius: 4px;
        box-shadow: 10px 10px 20px 0 rgba(black, .1);
    }

    &__Contact {
        color: $grey-90;

        &::before {
            margin: -4px 9px -6px 0;
            border: 2px solid $blue;
            border-radius: 50%;
            background: url('#{$basepath}img/bg-icons/medborgarskolan/new/question--blue.svg') no-repeat center center;
            background-size: 9px 14px;
        }

        a {
            font-weight: $normal;
        }
    }
}
