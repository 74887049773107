@import 'Styles/Medborgarskolan/includes';

/**
*
* CollaborationSearch
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CollaborationSearch.Base.module';

.CollaborationSearch {
    $root: &;

    &__Title {
        color: $blue;
    }

    &__Letter {
        color: $blue;
    }

    &__Form {
        @include media(sm) {
            padding: 16px;
            border-radius: 8px;
            background: $blue-20;
        }
    }

    &__SelectContainer {
        @extend %Form__ReactSelect;

        #{$root} & {
            :global {
                .Select {
                    border-radius: 8px;

                    .Select__placeholder {
                        color: $text-color;
                    }

                    .Select__control {
                        border: none;
                        background: white;
                        border-radius: 8px;

                        @include media(md) {
                            border-radius: 8px;
                        }

                        &:hover {
                            box-shadow: none;
                        }

                        // Background for dropdown in mobile
                        &::before {
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: auto;
                            width: 100%;
                            height: 100%;
                            margin: auto;
                            border: none;
                            border-radius: 8px;
                            background: white;

                            // Line seperating dropdown in desktop
                            @include media(md) {
                                width: 1px;
                                height: 40px;
                                left: 0;
                                border-radius: 0;
                                background: $grey-30;
                            }

                            #{$root}__SelectContainer:first-child & {
                                @include media(md) {
                                    opacity: 0;
                                }
                            }
                        }
                    }

                    &--is-focused {
                        outline: 3px solid $focus-color;

                        .Select__control {
                            // Hide line in desktop on focus
                            &::before {
                                @include media(md) {
                                    opacity: 0;
                                }
                            }
                        }
                    }

                    .Select__menu {
                        margin-top: 8px;
                        width: 100%;
                        max-height: 250px;
                        border-radius: 8px;
                        border: none;
                        box-shadow: 0px 2px 24px 0px rgba(black, .08);
                        overflow: hidden;

                        @include media(md) {
                            margin: 12px 0 0 0;
                            min-width: 370px;
                            display: block;
                        }
                    }

                    .Select__option {
                        display: flex;
                        align-items: center;
                        font-size: 1.6rem;
                        color: $text-color;
                        background: white;

                        &--is-disabled {
                            line-height: 24px;
                            font-size: 1.4rem;
                            font-weight: 400;
                            text-transform: inherit;
                            color: $grey-60;
                        }

                        &--is-focused {
                            background: $blue-20;
                        }

                        &--is-selected {
                            background: $blue-20;
                        }
                    }

                    .Select__option.is-disabled ~ .Select__option:not(.Select__option--is-disabled) {
                        padding-left: 16px;
                    }

                    .Select__menu-list {
                        max-height: 250px;
                        border-radius: 8px;
                    }

                    .Select__dropdown-indicator {
                        padding-right: 23px;

                        &::before {
                            width: 20px;
                            height: 20px;
                            background: url("#{$basepath}img/bg-icons/medborgarskolan/new/chevron--blue.svg")
                                no-repeat center center;
                            background-size: 20px 20px;
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
    }

    &__Button {
        font-family: $font-base;
        border-radius: 40px;
        background-color: $blue;
        background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/search--white.svg');
        background-size: 24px;

        &:hover {
            background-color: $blue-90;
        }
    }
}
