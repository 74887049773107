@import 'Styles/Base/includes';

/**
*
* ExpandableListModule
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ExpandableListModule {
    $root: &;

    margin-top: 40px;
    margin-bottom: 55px;

    @include media(sm) {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    @include media(md) {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    &__Container {
        @include u-wrap(site);
    }

    &__Title {
        margin-bottom: 24px;
        font-size: 2.4rem;

        @include media(sm) {
            margin-bottom: 28px;
            font-size: 3.2rem;
        }
    }

    &__List {
        display: flex;
        flex-wrap: wrap;
        margin-left: -16px;
    }

    &__Item {
        display: flex;
        flex-direction: column;
        position: relative;
        width: calc(100% - 16px);
        margin: 0 0 16px 16px;

        @include media(sm) {
            width: calc(50% - 16px);
        }
    }

    &__Footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 8px;

        @include media(sm) {
            margin-top: 24px;
        }
    }

    &__Button {
        position: relative;
        margin: 16px auto 0;
        z-index: 100;
    }
}
