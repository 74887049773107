@import 'Styles/Base/includes';

/**
*
* SlickSlider
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.SlickSlider {
    $root: &;

    &__Title {
        margin-bottom: 20px;
        padding-right: 80px;

        #{$root}--White & {
            color: white;
        }

        #{$root}--CardSubjectCampaign & {
            @include media(sm) {
                margin-bottom: 25px;
                font-size: 3.6rem;
            }
        }
    }

    &__Slider {
        position: relative;

        :global {
            // Third party override
            .slick-track {
                display: flex;
            }

            .slick-list {
                padding-left: 0 !important;
            }

            .slick-slider {
                margin-left: -16px; /* Same as gutter between slides */

                #{$root}--Half & {
                    @include media(sm) {
                        margin-left: -22px;
                    }
                }

                #{$root}--CardSubjectCampaign & {
                    @include media(sm) {
                        margin-left: -28px;
                    }
                }

                #{$root}--MobileInset & {
                    margin-right: -12px; /* Spacing to viewport */

                    @include media(sm) {
                        margin-right: 0;
                    }
                }
            }

            .slick-slide {
                flex: 1 0 auto;
                display: flex;
                flex-direction: column;
                height: auto;

                > div {
                    flex: 1 0 auto;
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    &__Slide {
        flex: 1 0 auto;
        display: flex !important;
        height: auto;
        padding-left: 16px; /* Gutter between slides */
        padding-bottom: 20px; /* Add space so there is room for a shadow */

        #{$root}--Half & {
            @include media(sm) {
                padding-left: 22px;
            }
        }

        #{$root}--CardSubjectCampaign & {
            @include media(sm) {
                padding-left: 28px;
            }
        }
    }

    &__Control {
        position: absolute;
        top: -58px;
        right: 0;
        width: 35px;
        height: 35px;
        border: 2px solid black;
        border-radius: 50%;
        background: transparent;
        transition: all .2s ease-in;

        #{$root}--White & {
            border-color: white;
        }

        &--Disabled {
            opacity: .2;
        }

        &--Next {
            right: 0;

            #{$root}--MobileInset & {
                right: 12px; /* Add for spacing being removed slick-slider */

                @include media(sm) {
                    right: 0;
                }
            }

            svg {
                left: 13px !important;
                transform: rotate(180deg);
            }
        }

        &--Prev {
            right: 44px;

            #{$root}--MobileInset & {
                right: 12px + 44px;  /* Add for spacing being removed slick-slider */
                
                @include media(sm) {
                    right: 44px;
                }
            }
        }

        &:not(#{$root}__Control--Disabled) {
            cursor: pointer;
        }

        svg {
            position: absolute;
            top: 10px;
            left: 11px;
            width: 7px;
            height: 11px;
            fill: black;
            transition: all .2s ease-in;

            #{$root}--White & {
                fill: white;
            }
        }

        &:hover:not(#{$root}__Control--Disabled) {
            background: black;

            @media(hover: none) {
                background: transparent;
            }

            #{$root}--White & {
                background: white;

                @media(hover: none) {
                    background: transparent;
                }
            }

            svg {
                fill: white;

                @media(hover: none) {
                    fill: black;
                }

                #{$root}--White & {
                    fill: black;

                    @media(hover: none) {
                        fill: white;
                    }
                }
            }
        }
    }
}
