@import 'Styles/Base/includes';

/**
*
* CollaborationSearch
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CollaborationSearch {
    $root: &;

    margin-top: 40px;
    margin-bottom: 40px;

    @include media(sm) {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    @include media(md) {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    &__Container {
        @include u-wrap(site);
    }

    &__Title {
        margin-bottom: 10px;
        font-size: 2.4rem;

        @include media(sm) {
            margin-bottom: 28px;
            font-size: 3.2rem;
        }
    }

    &__Text {
        margin-top: 8px;
        font-size: 1.6rem;

        @include media(sm) {
            margin-top: 12px;
            font-size: 1.8rem;
        }
    }

    &__Form {
        display: flex;
        margin-top: 30px;
    }

    &__SelectContainer {
        flex: 1 0 auto;
        margin-right: 16px;

        @extend %Form__ReactSelect;
    }

    &__Button {
        flex: 0 0 auto;
        width: 56px;
        height: 56px;
        color: white;
        border: 0;
        background-image: url('#{$basepath}img/bg-icons/base/search--white.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 16px auto;
        box-shadow: none;
        cursor: pointer;
        transition: background-color .3s ease-in-out;

        @include media(md) {
            position: relative;
            background-size: 16px auto;
        }
    }

    &__Count {
        margin: 16px 0 24px;
        font-size: 1.4rem;

        @include media(sm) {
            margin: 32px 0;
        }
    }

    &__Group {
        &:not(:first-child) {
            margin-top: 24px;

            @include media(sm) {
                margin-top: 48px;
            }
        }
    }

    &__Letter {
        margin-bottom: 16px;
        font-size: 2rem;

        @include media(sm) {
            font-size: 2.4rem;
        }
    }

    &__List {
        display: flex;
        flex-wrap: wrap;
        margin-left: -16px;
    }

    &__Column {
        position: relative;
        width: calc(100% - 16px);
        margin-left: 16px;

        @include media(sm) {
            width: calc(50% - 16px);
        }
    }

    &__Item {
        margin-bottom: 16px;
    }
}
