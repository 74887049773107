@mixin base-wrap($maxwidths, $list: (), $default: true) {

    margin-left: auto;
    margin-right: auto;

    @if $default {

        @if contains($list, site) {
            max-width: (map-get($maxwidths, site) + 60px);
        }

        @if contains($list, article) {
            max-width: (map-get($maxwidths, article) + 60px);
        }

        @if contains($list, content) {
            max-width: (map-get($maxwidths, content) + 60px);
        }

        @if contains($list, cookie) {
            max-width: (map-get($maxwidths, cookie) + 60px);
        }

        @if contains($list, title) {
            max-width: (map-get($maxwidths, title) + 60px);
        }

        padding-left: 12px;
        padding-right: 12px;

        @include media(sm) {
            padding-left: 20px;
            padding-right: 20px;
        }

        @include media(lg) {
            padding-left: 30px;
            padding-right: 30px;
        }

        @include media(xl) {
            padding-left: 60px;
            padding-right: 60px;

            @if contains($list, site) {
                max-width: (map-get($maxwidths, site) + 120px);
            }

            @if contains($list, article) {
                max-width: (map-get($maxwidths, article) + 120px);
            }

            @if contains($list, content) {
                max-width: (map-get($maxwidths, content) + 120px);
            }

            @if contains($list, cookie) {
                max-width: (map-get($maxwidths, cookie) + 120px);
            }

            @if contains($list, title) {
                max-width: (map-get($maxwidths, title) + 120px);
            }
        }
    } @else {
        @if contains($list, site) {
            max-width: (map-get($maxwidths, site));
        }

        @if contains($list, article) {
            max-width: (map-get($maxwidths, article));
        }

        @if contains($list, content) {
            max-width: (map-get($maxwidths, content));
        }

        @if contains($list, cookie) {
            max-width: (map-get($maxwidths, cookie));
        }

        @if contains($list, title) {
            max-width: (map-get($maxwidths, title));
        }
    }
}
