@import 'Styles/Base/includes';

/**
*
* Form
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Form {
    $root: &;

    &__Error {
        @extend %Form__Error;

        &--Large {
            @extend %Form__Error--Large;
        }
    }

    &__ThankYou {
        position: relative;
        padding: 30px;
        background: white;

        &::before {
            content: '';
            position: absolute;
            top: -1px;
            bottom: -1px;
            left: 0;
            width: 6px;
        }

        h2, h3, h4 {
            font-size: 2.2rem;
            margin-bottom: 10px;
        }

        p {
            max-width: 500px;
            margin-bottom: 15px;
        }

        a {
            color: $link-color;
            font-weight: $bold;
            text-decoration: none;

            &:hover {
                color: $link-hover-color;
                text-decoration: underline;
            }
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__Form {
        :global .form {
            &__title {
                margin-top: 15px;
                margin-bottom: 20px;

                @include media(sm) {
                    margin-top: 30px;
                }

                #{$root}--Module & {
                    display: none;
                }
            }

            &__field {
                @extend %Form__Field;

                &--checkbox-select-multiple {
                    @extend %Form__Field--CheckboxSelectMultiple;
                }

                &--radio-select {
                    @extend %Form__Field--RadioSelect;
                }

                &--select-multiple {
                    @extend %Form__Field--SelectMultiple;
                }

                &--consent {
                    @extend %Form__Field--Consent;
                }

                &--title {
                    @extend %Form__Field--Title;
                }
            }

            &__field-title {
                @extend %Form__FieldTitle;
            }

            legend {
                @extend %Form__Label;
            }

            &__required {
                @extend %Form__Required;
            }

            &__label {
                @extend %Form__Label;

                &.form__label--inside {
                    @extend %Form__Label--Inside;
                }

                &--inside-focus {
                    @extend %Form__Label--InsideFocus;
                }

                &--force-above {
                    @extend %Form__Label--ForceAbove;
                }

                // For checkbox and radio group labels
                &--group {
                    @extend %Form__Label--Group;
                }

                &--select {
                    @extend %Form__Label--Select;
                }

                &--select-focus {
                    @extend %Form__Label--SelectFocus;
                }

                &--checkbox {
                    @extend %Form__Label--Checkbox;
                }

                &--checkbox-checked {
                    @extend %Form__Label--CheckboxChecked;
                }

                &--radio {
                    @extend %Form__Label--Radio;
                }

                &--radio-checked {
                    @extend %Form__Label--RadioChecked;
                }

                &--radio-large {
                    @extend %Form__Label--RadioLarge;
                }

                &--disabled {
                    @extend %Form__Label--Disabled;
                }
            }

            &__input,
            &__select {
                @extend %Form__Input;

                &--checkbox,
                &--radio {
                    @extend %Form__Input--Checkbox;
                }

                &--label-above {
                    @extend %Form__Input--LabelAbove;
                }

                &[disabled] {
                    @extend %Form__Input--Disabled;
                }
            }

            &__select {
                @extend %Form__Select;

                &--multiple {
                    @extend %Form__Select--Multiple;
                }
            }

            &__react-select {
                @extend %Form__ReactSelect;

                :global {
                    .Select__control {
                        position: relative;
                        height: 58px;
                        border: 1px solid #eaeaea;
                        border-radius: 4px;
                        background: white;
                        transition: all .3s ease-in-out;

                        &:hover {
                            box-shadow: none;
                        }

                        &--is-focused {
                            &:not(.Select__control--menu-is-open) {
                                box-shadow: none;
                            }
                        }
                    }

                    .Select__multi-value-wrapper {
                        display: block;
                        position: relative;
                    }

                    .Select__placeholder {
                        color: #aaa;
                    }

                    .Select__value-container {
                        height: 58px !important;
                        padding-left: 20px !important;
                        font-size: 1.6rem !important;
                        font-weight: $medium !important;

                        @include media(md) {
                            height: 58px !important;
                            font-size: 1.6rem !important;
                            line-height: 68px !important;
                        }
                    }

                    .Select__input {
                        padding: 0;
                        font-weight: $medium !important;
                    }

                    .Select__menu {
                        border: 1px solid #eaeaea;
                        border-radius: 0;
                        transition: all .3s ease-in-out;
                    }
                }
            }

            &__field {
                &--checkbox-select-multiple .form__field-wrapper ul li,
                &--radio-select .form__field-wrapper ul li {
                    margin-top: 12px;
                }

                &--checkbox-input .form__field-wrapper,
                &--consent .form__field-wrapper,
                &--checkbox-select-multiple .form__field-wrapper ul li {
                    > label {
                        @extend %Form__Label--Checkbox;
                    }

                    > input:checked + label {
                        @extend %Form__Label--CheckboxChecked;
                    }
                }

                &--radio-select .form__field-wrapper ul li {
                    > label {
                        @extend %Form__Label--Radio;
                    }

                    > input:checked + label {
                        @extend %Form__Label--RadioChecked;
                    }
                }

                &--checkbox-select-multiple .form__field-wrapper > label,
                &--radio-select .form__field-wrapper > label,
                &--select-multiple .form__field-wrapper > label {
                    @extend %Form__Label--ForceAbove;
                }

                &--checkbox-select-multiple .form__field-wrapper > label,
                &--radio-select .form__field-wrapper > label {
                    @extend %Form__Label--Group;
                }

                &--checkbox-select-multiple .form__field-wrapper > label,
                &--radio-select .form__field-wrapper > label {
                    top: -30px;
                }

                &--checkbox-input .form__field-wrapper > label,
                &--consent .form__field-wrapper > label {
                    color: black;

                    p {
                        display: inline;
                    }
                }

                &--select .form__field-wrapper {
                    @extend %Form__FieldWrapper--Select;

                    > select {
                        + label {
                            @extend %Form__Label--Inside;
                        }

                        &:focus + label,
                        &.js-state-has-value + label {
                            @extend %Form__Label--InsideFocus;
                            @extend %Form__Label--SelectFocus;
                        }
                    }
                }

                &--select-multiple .form__field-wrapper {
                    > select {
                        @extend %Form__Select--Multiple;
                    }
                }
            }

            &__field-wrapper {
                @extend %Form__FieldWrapper;

                label {
                    @extend %Form__Label;
                }

                &--select {
                    @extend %Form__FieldWrapper--Select;

                    > select + label {
                        @extend %Form__Label--Select;
                    }
                }

                input[type="checkbox"],
                input[type="radio"] {
                    @extend %Form__Input--Checkbox;
                }

                > input:not([type="checkbox"]):not([type="radio"]),
                > textarea {
                    @extend %Form__Input;
                }

                > select {
                    @extend %Form__Select;
                }

                > input:not([type="checkbox"]):not([type="radio"]),
                > textarea {
                    + label {
                        @extend %Form__Label--Inside;
                    }

                    &:focus + label,
                    &.js-state-has-value + label {
                        @extend %Form__Label--InsideFocus;
                    }
                }
            }

            &__helptext {
                @extend %Form__Helptext;
            }

            &__submit {
                @extend %Form__Submit;

                #{$root}--Module & {
                    @extend %Form__Submit--Module;
                }

                // If submit is input instead of button, pseudo elements won't work so add shadow anyway
                &:is(input) {
                    box-shadow: -4px 4px 8px rgba(black, .2);

                    #{$root}--Module & {
                        box-shadow: none;
                    }
                }
            }
        }
    }
}
