@import 'Styles/Medborgarskolan/includes';

/**
*
* ExpandableListModule
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'ExpandableListModule.Base.module';

.ExpandableListModule {
    $root: &;

    &__Title {
        margin-bottom: 20px;
        font-weight: $bold;
        color: $blue;

        #{$root}--Small & {
            margin-bottom: 10px;
            font-size: 2rem;
            text-align: left;
        }

        @include media(sm) {
            margin-bottom: 30px;
        }
    }

    &__Button {
        @include u-button(secondary);
    }
}
