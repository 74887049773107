@import 'Styles/Base/includes';

/**
*
* CardCollaboration
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardCollaboration {
    $root: &;

    flex: 1 0 auto;

    &__Image {
        position: relative;
        background: white;

        &::before {
            content: '';
            display: block;
            width: 100%;

            #{$root}--Small & {
                height: 120px;

                @include media(sm) {
                    height: 192px;
                }
            }

            #{$root}--Large & {
                height: 135px;

                @include media(sm) {
                    height: 192px;
                }
            }
        }

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        #{$root}--Small & {
            margin-bottom: 24px;
        }

        #{$root}--IsLogo & {
            img {
                top: 24px;
                bottom: 24px;
                left: 24px;
                right: 24px;
                width: calc(100% - 48px);
                height: calc(100% - 48px);

                @include media(sm) {
                    top: 40px;
                    bottom: 40px;
                    left: 40px;
                    right: 40px;
                    width: calc(100% - 80px);
                    height: calc(100% - 80px);
                }
            }
        }
    }

    &__Container {
        display: block;
        position: relative;

        #{$root}--Large & {
            padding: 24px 16px;

            @include media(sm) {
                padding: 32px 28px 40px;
            }
        }

        #{$root}--Small & {
            padding: 22px 16px 21px;

            @include media(sm) {
                padding: 22px 28px;
            }
        }
    }

    &__TitleLink {
        @include u-button(link);

        position: relative;
        padding-right: 28px;
        display: block;
        width: 100%;
        color: $text-color;
        text-align: left;

        @include media(sm) {
            padding-right: 40px;
        }

        &:hover {
            color: $text-color;

            @media(hover: none) {
                color: $text-color;
            }
        }

        &[href] {
            &::after {
                content: '';
                position: absolute;
                top: 5px;
                right: 4px;
                display: inline-block;
                width: 24px;
                height: 24px;
                margin: -5px 0 0 10px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }
        }
    }

    &__Title {
        display: inline-block;

        #{$root}--Large & {
            font-size: 2rem;

            @include media(sm) {
                font-size: 2.4rem;
            }
        }

        #{$root}--Small & {
            font-size: 1.8rem;
            padding-bottom: 1px;
            margin-bottom: -1px;
            border-bottom: 2px solid transparent;
            transition: border-color .3s ease-in-out;

            @include media(sm) {
                font-size: 2rem;
            }
        }

        #{$root}__TitleLink:hover & {
            border-color: $text-color;

            @media(hover: none) {
                border-color: transparent;
            }
        }
    }

    &__Text {
        white-space: pre-line;

        #{$root}--Large & {
            margin-top: 12px;
        }
    }

    &__ButtonContainer {

        #{$root}--Large#{$root}--Expanded &,
        #{$root}--Large:not(#{$root}--IsExpandable) & {
            display: none;
        }

        #{$root}--Small & {
            position: absolute;
            top: 22px;
            right: 16px;

            @include media(sm) {
                right: 28px;
            }
        }

        #{$root}--Large & {
            position: relative;
            top: -60px; // Compensate for margin distance
            width: 100%;
            margin-top: 60px; // Spacing for gradient
            margin-bottom: -60px; // Compensate for negative top
            // background: white; // Define in themes

            &:before {
                content: '';
                position: absolute;
                top: -60px; // Move gradient above button area
                left: 0;
                height: 60px;
                width: 100%;
                // Define gradient in themes
            }
        }
    }

    &__Button {
        @include u-button(link);

        div {
            display: inline;
        }

        #{$root}--Small & {
            span:not(#{$root}__ButtonIcon) {
                @extend %sr-only;
            }
        }

        #{$root}--Large & {
            margin-top: 16px; // Spacing for button below gradient
        }
    }

    &__ButtonIcon {
        display: inline-block;
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        transition: background .2s ease-in-out;

        &::before,
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 9px;
            left: 6px;
            width: 9px;
            height: 2px;
            background: white;
        }

        &::after {
            transform: rotate(90deg);
        }

        #{$root}--Expanded & {
            &::after {
                display: none;
            }
        }

        #{$root}--Large & {
            margin: -4px 10px -4px 0;
        }

        #{$root}--Small & {
            position: absolute;
            top: -1px;
            right: 0;

            @include media(sm) {
                width: 28px;
                height: 28px;

                &::before,
                &::after {
                    top: 13px;
                    left: 8px;
                    width: 12px;
                    height: 2px;
                }
            }
        }
    }

    &__Content {
        #{$root}--Small & {
            padding-top: 20px;
        }

        #{$root}--Large & {
            transition: max-height .5s ease-in-out;
        }

        #{$root}--Large#{$root}--Cropped & {
            max-height: 110px;
            overflow: hidden;
        }

        #{$root}--Large#{$root}--Expanded & {
            max-height: none;
        }
    }

    &__PageLink {
        position: relative;
        display: inline-block;
        margin-top: 10px;
        font-size: 1.6rem;

        &:after {
            content: '';
            position: relative;
            top: 1px;
            display: inline-block;
            width: 15px;
            height: 12px;
            margin-left: 8px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            transition: transform .2s ease-in-out;
        }

        &:hover::after {
            transform: translateX(5px);

            @media(hover: none) {
                transform: translateX(0px);
            }
        }
    }

    &__Contact {
        margin-top: 24px;
        padding-top: 24px;
    }
    
    &__ContactTitle {
        margin-bottom: 4px;
        font-size: 1.6rem;
    }

    &__Link {
        display: block;
        margin-top: 8px;
        font-size: 1.6rem;

        &:hover {
            text-decoration: underline;

            @media(hover: none) {
                text-decoration: none;
            }
        }

        &::before {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            margin: 0 16px -6px 2px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
        }
    }
}
