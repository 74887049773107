@import 'Styles/Dansskolanentre/includes';

/**
*
* SlickSlider
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SlickSlider.Base.module.scss';

.SlickSlider {
    $root: &;

    &__Title {
        font-family: $font-base;
        font-size: 3rem;

        @include media(sm) {
            font-size: 4.8rem;
        }

        @include media(md) {
            font-size: 5.8rem;
        }
    }

    &__Slider {
        :global {
            // Third party override
            .slick-track {
                width: 100% !important;
            }
        }
    }

    &__Control {
        &:hover:not(#{$root}__Control--Disabled) {
            border-color: $primary-color;
            background: transparent;

            @media(hover: none) {
                border-color: black;
                background: transparent;
            }

            svg {
                fill: $primary-color;

                @media(hover: none) {
                    fill: black;
                }
            }
        }
    }
}
