%Form {
    // Add to body to override Base/extends/form.scss
    body &__Field {
        margin: 16px 0;

        @include media(md) {
            margin: 24px 0;
        }

        &--CheckboxSelectMultiple,
        &--RadioSelect {
        }

        &--SelectMultiple,
        &--LabelAbove {
            padding-top: 40px;
        }

        &--RadioSelect .Form__FieldWrapper ul li {
            > label {
                font-size: 1.6rem;

                &::before {
                    border: 1px solid $grey;
                }

                &::after {
                    top: 9px;
                    left: 7px;
                    width: 6px;
                    height: 6px;
                    background: white;
                }

                &:hover::before {
                    border-color: $blue;
                    box-shadow: 0 0 0 1px $blue;
                }
            }

            > input:checked + label {
                &::before {
                    border-color: $blue;
                    background: $blue;
                }
            }
        }

        &--CheckboxInput .Form__FieldWrapper,
        &--Consent .Form__FieldWrapper,
        &--CheckboxSelectMultiple .Form__FieldWrapper ul li {
            > label {
                font-size: 1.6rem;

                &::before,
                &::after {
                    top: 2px;
                    border-radius: 3px;
                }

                &::before {
                    border: 1px solid $grey;
                }

                &::after {
                    top: 6px;
                    width: 12px;
                    height: 12px;
                    background-image: url('../#{$basepath}img/bg-icons/medborgarskolan/new/check--white.svg');
                }

                &:hover::before {
                    border-color: $blue;
                    box-shadow: 0 0 0 1px $blue;
                }
            }

            > input:checked + label {
                &::before {
                    border-color: $blue;
                    background: $blue;
                }
            }
        }
    }

    body &__FieldTitle {
        color: $blue;
        font-weight: $bold;
        font-family: $font-heading;
    }

    body &__Label {
        color: $text-color;
        font-size: 1.8rem;
        font-weight: $normal;
        font-family: $font-base;

        &--Inside {
            top: 14px;
            left: 16px;
            right: 16px;
            color: $grey-70;
        }

        &--InsideFocus {
            top: 9px !important;
            color: $text-color;
            font-size: 1.1rem;
        }

        &--ForceAbove {
            top: -40px;
        }

        &--Group {
            color: $blue;
            font-weight: $bold;
            font-family: $font-heading;
        }

        &--Checkbox {
            font-size: 1.6rem;

            &::before,
            &::after {
                top: 2px;
                border-radius: 3px;
            }

            &::before {
                border: 1px solid $grey;
            }

            &::after {
                top: 6px;
                width: 12px;
                height: 12px;
                background-image: url('../#{$basepath}img/bg-icons/medborgarskolan/new/check--white.svg');
            }

            &:hover::before {
                border-color: $blue;
                box-shadow: 0 0 0 1px $blue;
            }
        }

        &--CheckboxChecked {
            &::before {
                border-color: $blue;
                background: $blue;
            }
        }

        &--Radio {
            font-size: 1.6rem;

            &::before {
                border: 1px solid $grey;
            }

            &::after {
                top: 9px;
                left: 7px;
                width: 6px;
                height: 6px;
                background: white;
            }

            &:hover::before {
                border-color: $blue;
                box-shadow: 0 0 0 1px $blue;
            }
        }

        &--RadioChecked {
            &::before {
                border-color: $blue;
                background: $blue;
            }
        }

        &--RadioLarge {
            margin: 0;

            &::after {
                top: 25px;
                left: 23px;
            }

            @include media(xs) {
                &::after {
                    top: 29px;
                    left: 27px;
                }
            }
        }
    }

    body &__Input,
    body &__Select {
        padding: 25px 16px 10px;
        font-weight: $normal;
        border-color: $grey;
        border-radius: 8px;

        &:focus {
            border-color: $blue;
            box-shadow: 0 0 0 1px $blue;
        }

        &:hover {
            border-color: $blue;
        }

        &--LabelAbove {
            padding-top: 18px !important;
            padding-bottom: 17px !important;
        }
    }

    body &__Select {
        &--Multiple {
            margin-top: 0;
            padding: 8px 16px;
        }
    }

    body &__ReactSelect {
        :global {
            .Select {
                .Select__control {
                    border-color: $grey;
                    border-radius: 8px;
                    
                    &--is-focused {
                        border-color: $blue;
                        box-shadow: 0 0 0 1px $blue;
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }
                }

                .Select__value-container {
                    padding-left: 16px !important;
                    font-weight: $normal !important;
                }

                .Select__dropdown-indicator {
                    padding-right: 16px;

                    &::before {
                        top: 2px;
                        width: 24px;
                        height: 24px;
                        background-image: url('../#{$basepath}img/bg-icons/medborgarskolan/new/chevron--blue.svg');
                        background-size: contain;
                        transform: rotate(90deg);
                        opacity: 1;
                        z-index: 1;
                    }
                }

                .Select__menu {
                    margin-top: 8px;
                    border: 1px solid $grey-30;
                }

                .Select__menu,
                .Select__menu-list {
                    border-radius: 8px;
                }

                .Select__control--menu-is-open {
                    .Select__dropdown-indicator::before {
                        top: 2px !important;
                    }
                }
            }
        }
    }

    body &__FieldWrapper {
        &--Select {
            &::before {
                height: 50px;
                border-radius: 8px;
                bottom: auto;
            }

            &::after {
                width: 24px;
                height: 24px;
                right: 16px;
                top: 16px;
                bottom: auto;
                background-image: url('../#{$basepath}img/bg-icons/medborgarskolan/new/chevron--blue.svg');
                transform: rotate(90deg);
            }
        }
    }

    body &__Submit {
        @include u-button(primary);

        &:is(input) {
            box-shadow: none !important;
        }
    }
}
