@import 'Styles/Medborgarskolan/includes';

/**
*
* CardCollaboration
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardCollaboration.Base.module';

.CardCollaboration {
    $root: &;

    border: 1px solid $blue-20;
    border-radius: 8px;
    background: $blue-20;
    overflow: hidden;

    &__TitleLink {
        &[href] {
            &::after {
                width: 24px;
                height: 24px;
                background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/external--blue.svg');
            }
        }
    }

    &__Title {
        color: $blue;
        font-weight: $bold;

        #{$root}__TitleLink:hover & {
            border-color: $blue;

            @media(hover: none) {
                border-color: transparent;
            }
        }
    }

    &__ButtonContainer {
        #{$root}--Large & {
            background: $blue-20;

            &:before {
                background: linear-gradient(to top, $blue-20, rgba($blue-20, .68), rgba($blue-20, 0));
            }
        }
    }

    &__Button {
        color: $blue;
        font-size: 1.6rem;
        font-weight: $medium;

        &:hover {
            color: $blue;

            @media(hover: none) {
                color: $blue;
            }
        }
    }

    &__ButtonIcon {
        background: $blue;

        #{$root}__Button:hover &,
        #{$root}__TitleLink:hover & {
            background: $blue-90;
        }

        &::before {
            display: none;
        }

        &::after {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 16px;
            height: 16px;
            margin: auto;
            background: url('#{$basepath}img/bg-icons/medborgarskolan/new/plus--white.svg') no-repeat center center;
            background-size: contain;
        }

        #{$root}--Small & {
            @include media(sm) {
                &::before,
                &::after {
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    &__PageLink {
        color: $blue;
        font-weight: $bold;

        &:after {
            width: 20px;
            height: 20px;
            margin: -4px 0 -4px 8px;
            background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/arrow--blue.svg');
        }
    }

    &__Contact {
        border-top: 1px solid $blue-30;
    }
    
    &__ContactTitle {
        color: $blue;
        font-weight: $bold;
    }

    &__Link {
        color: $blue;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }

        &::before {
            background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/external--blue.svg');
        }

        &[href^='mailto:']::before {
            background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/mail--blue.svg');
        }

        &[href^='tel:']::before {
            background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/phone--blue.svg');
        }
    }
}
