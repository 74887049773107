%Form {
    // Add to body to override Base/extends/form.scss
    body &__FieldTitle {
        font-size: 1.8rem;
        font-weight: $bold;
    }

    body &__Label {
        color: $text-color;
        font-weight: $normal;
        line-height: 1.6;

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;

                @media(hover: none) {
                    text-decoration: underline;
                }
            }
        }

        &--ForceAbove {
            font-weight: $medium;
        }

        &--Checkbox {
            &::before {
                border: 1px solid $grey-50;
                border-radius: 3px;
            }

            &::after {
                top: 6px;
                height: 12px;
                background-image: url(../#{$basepath}img/bg-icons/dansskolanentre/check--white.svg);
            }
        }

        &--CheckboxChecked {
            &::before {
                border-color: $primary-color;
                background: $primary-color;
            }
        }

        &--Radio {
            text-transform: none;

            &:hover:before {
                border-color: $primary-color;
            }

            &::before {
                border: 1px solid $grey-50;
            }

            &::after {
                background: $primary-color;
            }
        }
    }

    body &__Input,
    body &__Select {
        font-weight: $light;
        border: 1px solid $grey-50;
        border-radius: 4px;

        &:focus {
            border-color: black;
        }
    }

    body &__ReactSelect {
        :global {
            .Select {
                .Select__control {
                    border: 1px solid $grey-50;
                    border-radius: 4px;

                    &--is-focused {
                        &,
                        + .Select__menu {
                            border-color: black;
                        }
                    }
                }

                .Select__menu {
                    border: 1px solid $grey-50;
                }
            }
        }
    }

    body &__Field {
        &--CheckboxSelectMultiple .Form__FieldWrapper ul li,
        &--RadioSelect .Form__FieldWrapper ul li {
            padding-top: 0;
        }

        &--CheckboxInput .Form__FieldWrapper,
        &--Consent .Form__FieldWrapper,
        &--CheckboxSelectMultiple .Form__FieldWrapper ul li {
            > label {
                &::before {
                    border: 1px solid $grey-50;
                    border-radius: 3px;
                }

                &::after {
                    top: 6px;
                    height: 12px;
                    background-image: url(../#{$basepath}img/bg-icons/dansskolanentre/check--white.svg);
                }
            }

            > input:checked + label {
                &::before {
                    border-color: $primary-color;
                    background: $primary-color;
                }
            }
        }

        &--RadioSelect .Form__FieldWrapper ul li {
            > label {
                text-transform: none;

                &:hover:before {
                    border-color: $primary-color;
                }

                &::before {
                    border: 1px solid $grey-50;
                }

                &::after {
                    background: $primary-color;
                }
            }
        }
    }

    body &__FieldWrapper {
        &--Select {
            &::before {
                border-radius: 4px;
            }
        }
    }

    body &__Helptext {
        margin-top: 5px;
        color: $text-color;
        font-size: 1.4rem;

        a {
            color: $link-color;
            text-decoration: underline;

            &:hover {
                text-decoration: none;

                @media(hover: none) {
                    text-decoration: underline;
                }
            }
        }
    }

    body &__Submit {
        @include u-button(solid);

        font-size: 1.6rem;
        height: 50px;
    }
}
